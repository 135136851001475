import { DialogActions, makeStyles, TextField } from "@material-ui/core";
import styled from "styled-components/macro";

export const CustomDialogActions = styled(DialogActions)`
  padding-right: 24px;
`;

export const NameField = styled(TextField)`
  .MuiInputBase-root {
    height: 56px;
  }
  .MuiInputBase-input {
    font-family: CircularXXWeb-Regular;
  }
`;

export const useStyles = makeStyles(() => ({
  carMakeAutocomplete: {
    marginBottom: "16px",
  },
}));
