import { User as ConfigCatUser } from "configcat-common";
import { useFeatureFlag } from "configcat-react";
import React from "react";
import { useSelector } from "react-redux";
import Maintenance from "src/pages/fallback/Maintenance";
import { ConfigCatFlags } from "src/types/enum";

import { RootState } from "../redux";

export const withEmergencyShutdown = (Component: React.FC) =>
  React.memo((props) => {
    const user = useSelector((state: RootState) => state.authReducer.user);
    let configCatUser = undefined;
    if (user !== undefined) {
      configCatUser = new ConfigCatUser(`${user?.id}`, undefined, undefined, {
        club: user?.clubname,
        // in theory this should be allowed to be boolean, but I think our version of the SDK is just old
        admin: user?.is_admin ? "true" : "false",
      });
    }
    const { value: emergencyShutDownValue } = useFeatureFlag(
      ConfigCatFlags.EMERGENCY_SHUT_DOWN,
      false,
      configCatUser
    );

    return (
      <React.Fragment>
        {emergencyShutDownValue ? <Maintenance /> : <Component {...props} />}
      </React.Fragment>
    );
  });
