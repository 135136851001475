import { Box, Link, Typography, useTheme } from "@material-ui/core";
import React, { useEffect, useState } from "react";

import PaintBuilder from "../../assets/paint-builder-logo.svg";

export const ProUpgrade = React.memo(() => {
  const theme = useTheme();
  const [show, setShow] = useState(false);
  useEffect(() => {
    const i = setTimeout(() => {
      setShow(true);
    }, 2000);

    return () => clearTimeout(i);
  });
  return (
    <Box bgcolor={theme.palette.background.default} height="100vh" p={0} m={0}>
      <Box
        width="100%"
        height="100%"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        {!show ? null : (
          <Box alignItems="center" flexDirection="column" display="flex">
            <Box mb={2}>
              <img
                src={PaintBuilder}
                alt="PaintBuilder Logo"
                width="400px"
                style={{ marginBottom: "2rem" }}
              />
            </Box>
            <Box mb={12}>
              <Typography variant="h1" color="textPrimary" align="center">
                Design custom paints in your browser.
              </Typography>
            </Box>
            <Box mb={12} sx={{ maxWidth: 500 }}>
              <Typography color="textPrimary">
                Make your cars your own without complicated photo-editing
                software. Paint Builder is simple enough to use for beginners,
                yet powerful enough for advanced painting pros. Included with
                Trading Paints Pro membership.
              </Typography>
            </Box>
            <Box mb={6}>
              <Link
                href="https://www.tradingpaints.com/page/Builder"
                component="button"
                variant="body2"
                style={{
                  color: "white",
                  fontSize: "1.5rem",
                  padding: "1rem 2rem",
                  backgroundColor: "#555bbb",
                  borderRadius: "0.5rem",
                  border: "#000 0.125rem solid",
                  fontWeight: 700,
                  textTransform: "uppercase",
                }}
              >
                Learn More About Trading Paints Pro
              </Link>
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
});

export default ProUpgrade;
