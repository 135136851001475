import { Button, FormControlLabel, Typography } from "@material-ui/core";
import styled from "styled-components/macro";

export const CustomButton = styled(Button)`
  font-size: 16px;
  font-weight: 400;
`;

export const CustomFormControlLabel = styled(FormControlLabel)`
  margin-left: 8px;
`;

export const CustomTypography = styled(Typography)`
  line-height: 30px;
`;
